import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {context as ProjectTasksContext} from 'lib/projectTasks';

import {useToggledBorrowerProject} from '@renofi/graphql';

import useApplicationState from '../../hooks/useApplicationState';
import Modals from '../Modals';
import useTaskHandlers from '../../hooks/useTaskHandlers';
import AppraisalTask from '../Renovation/components/AppraisalTask';
import useDocumentHandlers from '../../hooks/useDocumentHandlers';
import {TaskCard} from '../styled';
import {filterByTab} from '../../filterByTab';

const Appraisal = ({config, tab}) => {
  const {appraisal} = useContext(ProjectTasksContext);
  const tasks = appraisal.filter((task) => filterByTab(task, tab));
  const {project} = useToggledBorrowerProject();
  const projectId = project?.id;
  const {
    onConfirmUpload,
    onShowPrompt,
    onRemoveDocument,
    loading: isSubmitting,
    removedDocument,
    uploadedDocument,
  } = useTaskHandlers({tasks, projectId, facet: 'appraisal'});
  const {applicationState, onCloseRejection, onCloseAdditionalRequest} =
    useApplicationState();
  const {onAcceptFiles} = useDocumentHandlers({
    tasks,
    projectId,
    facet: 'appraisal',
  });

  if (isEmpty(tasks)) return null;

  return (
    <Modals
      tasks={tasks}
      loading={isSubmitting}
      config={config}
      rejection={applicationState?.rejection}
      removeIds={removedDocument}
      addFilesData={uploadedDocument}
      additionalRequest={applicationState?.additionalRequest}
      onAcceptFiles={onAcceptFiles}
      onConfirmUpload={onConfirmUpload}
      onShowPrompt={onShowPrompt}
      onRemoveDocument={onRemoveDocument}
      onCloseRejection={onCloseRejection}
      onCloseAdditionalRequest={onCloseAdditionalRequest}>
      {Boolean(tasks.length) ? (
        <>
          {tasks.map((task) => (
            <TaskCard key={task.id}>
              <AppraisalTask task={task} />
            </TaskCard>
          ))}
        </>
      ) : null}
    </Modals>
  );
};

Appraisal.propTypes = {
  onTaskComplete: PropTypes.func.isRequired,
  config: PropTypes.object,
};

export default Appraisal;
