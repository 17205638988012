import {useLayoutEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {head, pipe} from 'ramda';
import {Help} from 'modules/layout';

import {sendEvent} from '@renofi/analytics';
import {Loader, Text} from '@renofi/components-internal';
import {useBorrowerProjects} from '@renofi/graphql';

const sortByCreatedDate = (projects = []) =>
  projects.toSorted(
    (left, right) => new Date(right?.createdAt) - new Date(left?.createdAt),
  );

const ProjectRedirect = () => {
  const {fetch} = useBorrowerProjects({lazy: true});
  const history = useHistory();
  const [noProjects, setNoProjects] = useState(false);

  useLayoutEffect(() => {
    (async () => {
      const rsp = await fetch();
      const projects = rsp?.data?.borrowerProjects || [];
      const mostRecent = pipe(sortByCreatedDate, head)(projects);

      if (rsp?.errors?.length) return;
      if (mostRecent) {
        history.replace(`/${mostRecent.id}`);
      } else {
        sendEvent('Secure/No-Projects');
        setNoProjects(true);
      }
    })();
  }, []);

  if (noProjects) {
    return (
      <div style={{maxWidth: '800px', margin: 'auto', paddingTop: '3rem'}}>
        <div style={{textAlign: 'center'}}>
          <Text pb={3} fontSize={18}>
            Looking for something?
          </Text>
          <Text pb={3} fontSize={18}>
            Schedule a call with a loan officer today to explore the smartest
            ways to finance your renovation.
          </Text>
        </div>
        <Help />
      </div>
    );
  }

  return <Loader labelText="Fetching default project" />;
};

export default ProjectRedirect;
