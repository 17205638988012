export default [
  {
    complete: false,
    id: 'subject-property',
    value: 'Subject Property',
    path: 'subject-property',
    flat: false,
    children: [
      {
        id: 'subject-property/mortgage-details',
        value: 'Mortgage details',
        current: true,
      },
      {
        id: 'subject-property/mortgage-statement',
        value: 'Mortgage statement',
        skippable: true,
      },
      {
        id: 'subject-property/insurance',
        value: 'Homeowners insurance policy',
        skippable: true,
      },
      {
        id: 'subject-property/tax',
        value: 'Property tax',
        skippable: true,
        hidden: true,
      },
      {
        id: 'subject-property/fees',
        value: 'Homeowners association fees',
        skippable: true,
        hidden: true,
      },
    ],
  },
  {
    complete: false,
    id: 'additional',
    value: 'Additional properties',
    path: 'additional',
  },
  {
    complete: false,
    id: 'sold',
    value: 'Recently sold properties',
    path: 'sold',
  },
];

export const additionalPropertyStep = (count = 1) => ({
  complete: false,
  id: `additional/${count}/root`,
  value: `Property #${count}`,
  path: `additional-${count}-root`,
  flat: true,
  removable: true,
  children: [
    {
      id: `additional/${count}/address`,
      value: 'Address',
    },
    {
      id: `additional/${count}/details`,
      value: 'Details',
    },
    {
      id: `additional/${count}/mortgage-details`,
      value: 'Mortgage details',
    },
    {
      id: `additional/${count}/mortgage-statement`,
      skippable: true,
      value: 'Mortgage statement',
    },
    {
      id: `additional/${count}/tax`,
      value: 'Property tax',
      skippable: true,
      hidden: true,
    },
    {
      id: `additional/${count}/insurance`,
      value: 'Homeowners insurance policy',
      skippable: true,
      hidden: true,
    },
    {
      id: `additional/${count}/fees`,
      value: 'Homeowners association fees',
      skippable: true,
      hidden: true,
    },
    {
      id: `additional/${count}/lease`,
      value: 'Lease agreement',
      skippable: true,
      hidden: true,
    },
  ],
});

export const soldPropertyStep = (count = 1) => ({
  complete: false,
  id: `sold/${count}/root`,
  value: `Recently sold #${count}`,
  path: `sold-${count}-root`,
  flat: true,
  removable: true,
  children: [
    {
      id: `sold/${count}/address`,
      value: 'Address',
    },
    {
      id: `sold/${count}/details`,
      value: 'Details',
    },
  ],
});
