import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, propOr} from 'ramda';
import {useCurrentTask} from 'modules/project';

import {
  ConfirmCloseTaskModal,
  ModalWithButtons,
  Modal,
} from '@renofi/components-internal';
import {minSmall} from '@renofi/theme/src/breakpoints';
import isFunction from '@renofi/utilities/src/isFunction';

import Context from './context';
import {getIsSetAsFinishedDisabled} from './utils';

const HEADER_CSS = {
  '> span': {
    maxWidth: 370,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

function TasksWizardModalContainer({children}) {
  const [confirmClose, setConfirmClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [dirty, setDirty] = useState(false);

  const showModal = !isEmpty(modalProps);
  const taskId = propOr(null, 'taskId', modalProps);
  const facet = propOr(null, 'facet', modalProps);
  const {sendEvent, task = {}} = useCurrentTask({facet, taskId});
  const {details, documents, taskType, title} = task;
  const componentProps = {details, documents, taskId, taskType, title};

  useEffect(() => {
    setDisabled(getIsSetAsFinishedDisabled(task, [loading]));
  }, [loading, task]);

  const onClose = useCallback(() => {
    resetAll();
    if (modalProps.onClose) {
      modalProps.onClose();
    }
  });

  const resetAll = useCallback(() => {
    setModalProps({});
    setConfirmClose(false);
    setLoading(false);
    setDisabled(false);
    setDirty(false);
  }, []);

  const onClickClose = useCallback(() => {
    if (dirty) {
      setConfirmClose(true);
    } else {
      sendEvent('Secure/Task-Closed');
      resetAll();
    }
  }, [dirty]);

  const onConfirm = useCallback(() => {
    sendEvent('Secure/Task-ConfirmClosed', {
      taskId,
      taskType,
    });
    onClose();
  }, [taskId, taskType]);

  const onAccept = useCallback(() => {
    setConfirmClose(false);
    if (modalProps.onAccept) {
      modalProps.onAccept({onClose, setLoading});
    }
  }, [modalProps]);

  const onReject = useCallback(() => {
    if (modalProps.onReject) {
      modalProps.onReject();
    }
  }, [modalProps]);

  const openModal = (props) => {
    sendEvent('Secure/Task-Started');
    setModalProps(props);
  };

  const acceptLabel = useMemo(() => {
    const label = propOr('', 'acceptLabel', modalProps);
    return isFunction(label) ? label() : label;
  }, [modalProps]);

  const providerValue = {
    dirty,
    loading,
    onClose,
    openModal,
    setDirty,
    setLoading,
  };

  const {component: Component, hasButtons, ...restOfProps} = modalProps;
  const ModalComponent = hasButtons ? ModalWithButtons : Modal;

  return (
    <Context.Provider value={providerValue}>
      {children}
      {showModal && (
        <ModalComponent
          id="tasksWizardContainer"
          disableEscape
          contentCss={{
            display: 'flex',
            flexDirection: 'column',
          }}
          css={{
            [minSmall]: {
              minHeight: 600,
            },
          }}
          headerCss={HEADER_CSS}
          loading={loading}
          width={taskType === 'renovation_contract' ? 840 : 600}
          show={showModal}
          disabled={disabled}
          showReject={false}
          {...restOfProps}
          onClose={onClickClose}
          acceptLabel={acceptLabel}
          {...(hasButtons
            ? {
                onAccept: onAccept,
                onReject: onReject,
              }
            : {})}>
          {Component && (
            <Component {...componentProps} onChange={() => setDirty(true)} />
          )}
        </ModalComponent>
      )}

      <ConfirmCloseTaskModal
        onCancelConfirm={() => setConfirmClose(false)}
        onConfirm={onConfirm}
        show={confirmClose}
      />
    </Context.Provider>
  );
}

TasksWizardModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(TasksWizardModalContainer);
