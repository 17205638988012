import React, {Fragment, useContext, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import lazy from 'lib/asyncComponent';
import {TaskItem} from 'modules/layout';
import {TASK_STATUSES, useCurrentTask} from 'modules/project';

import {Box, Button, Link, Text} from '@renofi/components-internal';
import {isMobile} from '@renofi/theme/src/breakpoints';
import {ReconfContext} from '@renofi/utilities/src/reconf';
import {hasCoBorrowerInProject} from '@renofi/utilities';
import {taskTypes} from '@renofi/utilities/src/enums';

import {
  OUTSTANDING_LABELS,
  PENDING_LABELS,
} from '../../../BorrowerDetails/constants';

import SoftCreditWizard from './Wizard';

const PrimaryBorrowerDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "soft-pull-primary-borrower" */ './PrimaryBorrower'
    ),
);

const CoBorrowerDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "soft-pull-co-borrower" */ './CoBorrowerDetails'
    ),
);

const Task = (props) => {
  const {id, status, taskType, softCreditCheck, project} = props;
  const {
    coborrowerAuthorizedRenofiToVerifyCreditAt,
    primaryApplicantSubmittedAt,
  } = softCreditCheck || {};

  const {config} = useContext(ReconfContext) || {};
  const [showWizard, setShowWizard] = useState(false);
  const {sendEvent} = useCurrentTask({taskId: id});

  const vendor = propOr(null, 'softPullVendor', config);
  const isFactualData = vendor === 'factual_data';

  const hasCoBorrower = hasCoBorrowerInProject(project);
  const hasCoBorrowerConsented = Boolean(
    coborrowerAuthorizedRenofiToVerifyCreditAt,
  );
  const hasPrimaryApplicantSubmitted = Boolean(primaryApplicantSubmittedAt);
  const hasCoBorrowerNotConsented = hasCoBorrower && !hasCoBorrowerConsented;

  const isCompleted = TASK_STATUSES.completed === status;
  const isOutstanding = TASK_STATUSES.outstanding === status;
  const isInProcessing = TASK_STATUSES.processing === status;

  const isInProgress = !hasCoBorrower
    ? isOutstanding
    : isOutstanding && !hasPrimaryApplicantSubmitted;
  const isProcessing = !hasCoBorrower
    ? isInProcessing
    : isOutstanding && hasPrimaryApplicantSubmitted;
  const iSoftPullTask = taskType === taskTypes.soft_credit_check;

  const inProgressLabel = isInProgress ? OUTSTANDING_LABELS[taskType] : null;
  const pendingLabel = isProcessing ? PENDING_LABELS[taskType] : null;
  const label = softCreditCheck ? inProgressLabel || pendingLabel : '';
  const showSoftPullTask =
    (isCompleted || isProcessing) && iSoftPullTask && softCreditCheck;
  const softPullStatus = isProcessing ? TASK_STATUSES.processing : status;

  const onLaunchWizard = () => {
    const hasCoBorrowerConsentPending =
      hasCoBorrower && hasPrimaryApplicantSubmitted && !hasCoBorrowerConsented;

    const eventObj = {
      taskType: 'soft-pull-authorization',
      ...(hasCoBorrowerConsentPending
        ? {taskStatus: 'pending-coborrower-consent'}
        : {}),
    };

    sendEvent('Secure/Task-Started', eventObj);
    setShowWizard(true);
  };

  const onCloseWizard = () => {
    sendEvent('Secure/Task-Closed', {taskType: 'soft-pull-authorization'});
    setShowWizard(false);
  };

  return (
    <Fragment>
      <TaskItem
        button={
          !isCompleted || hasCoBorrowerNotConsented ? (
            <Button
              block={isMobile()}
              medium={isMobile()}
              onClick={onLaunchWizard}
              variant={isInProgress ? 'primary' : 'secondary'}>
              {label}
            </Button>
          ) : null
        }
        customTaskStatus={
          hasCoBorrower && isProcessing
            ? 'pending co-borrower authorization'
            : null
        }
        status={softPullStatus}
        task={props}>
        <>
          {showSoftPullTask && (
            <Box flex={1}>
              <PrimaryBorrowerDetails
                softCreditCheck={softCreditCheck}
                project={project}
              />
              {hasCoBorrower && (
                <CoBorrowerDetails
                  softCreditCheck={softCreditCheck}
                  taskId={id}
                />
              )}
              {isFactualData && (
                <Text mt={3}>
                  You can{' '}
                  <Link
                    href="https://www.factualdata.com/consumer-assistance"
                    blank>
                    request a copy of your credit report
                  </Link>{' '}
                  from our credit report partner, Factual Data.
                </Text>
              )}
            </Box>
          )}
        </>
      </TaskItem>
      {showWizard && (
        <SoftCreditWizard
          task={props}
          onWizardClose={onCloseWizard}
          project={project}
        />
      )}
    </Fragment>
  );
};

Task.propTypes = {
  id: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  softCreditCheck: PropTypes.shape({
    id: PropTypes.string,
    coborrowerAuthorizedRenofiToVerifyCreditAt: PropTypes.string,
    taskId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    email: PropTypes.string,
    softPullConsentAt: PropTypes.string,
    coborrowerSoftPullConsentAt: PropTypes.string,
    primaryApplicantSubmittedAt: PropTypes.string,
    residentialAddressStreet: PropTypes.string,
    residentialAddressCity: PropTypes.string,
    residentialAddressState: PropTypes.string,
    residentialAddressZip: PropTypes.string,
    coborrowerFirstName: PropTypes.string,
    coborrowerLastName: PropTypes.string,
    coborrowerDateOfBirth: PropTypes.string,
    coborrowerPhoneNumber: PropTypes.string,
    coborrowerEmail: PropTypes.string,
    coborrowerLivesAtTheSameAddress: PropTypes.bool,
    coborrowerResidentialAddressStreet: PropTypes.string,
    coborrowerResidentialAddressCity: PropTypes.string,
    coborrowerResidentialAddressState: PropTypes.string,
    coborrowerResidentialAddressZip: PropTypes.string,
  }),
};

export default Task;
