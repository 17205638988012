import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {useCurrentTask} from 'modules/project';
import {ManageDocuments} from 'modules/tasks-wizard';

import {Box, Text} from '@renofi/components-internal';
import {basic80} from '@renofi/theme/src/colors';

import SmsConsent from '../../../Consent';
import {SMS_CONSENT_RENOVATION_PROGRESS} from '../../constants';
import ChecklistHelpWidget from '../ChecklistHelpWidget';

const RenovationWizard = ({onChange, taskId}) => {
  const {task} = useCurrentTask({taskId});

  const canHaveDocument = propOr(false, 'canHaveDocument', task);
  const taskType = propOr(null, 'taskType', task);
  const title = propOr('Upload documents', 'title', task);
  const details = propOr('Please upload documents task', 'details', task);

  if (!task?.id) {
    return null;
  }

  return (
    <Fragment>
      {taskType === 'renovation_contract' ? (
        <Box mb={4}>
          <ChecklistHelpWidget />
        </Box>
      ) : null}

      <Box mb={4}>
        <Text mb={2} fontSize={18}>
          {title}
        </Text>

        <Text color={basic80}>{details}</Text>
        {taskType === SMS_CONSENT_RENOVATION_PROGRESS && (
          <SmsConsent taskId={taskId} {...task?.smsConsentRenovationProgress} />
        )}
      </Box>

      {canHaveDocument && (
        <ManageDocuments onChange={onChange} taskId={taskId} />
      )}
    </Fragment>
  );
};

RenovationWizard.propTypes = {
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default RenovationWizard;
