import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {useIsImpersonation} from '@renofi/utilities/src/hooks';
import {noop} from '@renofi/utilities';
import {isMobile} from '@renofi/theme';

import {Blank, Title, Wrapper} from './styled';

const StickyHeader = ({backButton, onBackClick = noop, title, children}) => {
  const [scrollShadow, setScrollShadow] = useState(false);
  const isImpersonating = useIsImpersonation();

  const onScroll = useCallback(() => {
    setScrollShadow(window.scrollY > (isMobile() ? -100 : 20));
  }, [isImpersonating]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Wrapper impersonation={isImpersonating} scrollShadow={scrollShadow}>
      <Blank left />
      <Title
        role="sticky-header-title"
        text={title}
        backButton={backButton}
        onBackClick={onBackClick}
      />
      <Blank />
      {children}
    </Wrapper>
  );
};

StickyHeader.propTypes = {
  backButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default StickyHeader;
