import React, {useContext, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {anyPass, filter, isEmpty, propEq, propOr, reject} from 'ramda';
import {context as ProjectTasksContext} from 'lib/projectTasks';

import {Loader} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';

import useApplicationState from '../../hooks/useApplicationState';
import useTaskHandlers from '../../hooks/useTaskHandlers';
import useDocumentHandlers from '../../hooks/useDocumentHandlers';
import Modals from '../Modals';
import {WizardUploadTask} from '../Task';
import {filterByTab} from '../../filterByTab';
import {TaskCard} from '../styled';

import ChecklistHelpWidget from './components/ChecklistHelpWidget';
import InsuranceWizard from './components/InsuranceWizard';
import RenovationWizard from './components/RenovationWizard';
import SmsConsentDetails from './components/SmsConsentDetails';
import {
  RENOVATION_CONTRACT,
  SMS_CONSENT_RENOVATION_PROGRESS,
} from './constants';

const getExtraDetails = (taskType) => {
  switch (taskType) {
    case RENOVATION_CONTRACT:
      return ChecklistHelpWidget;
    case SMS_CONSENT_RENOVATION_PROGRESS:
      return SmsConsentDetails;
    default:
      return null;
  }
};

const homeInsurance = propEq('taskType', 'home_insurance');
const recentTransactions = propEq('taskType', 'appraisal_recent_transactions');
const smsConsent = propEq('taskType', SMS_CONSENT_RENOVATION_PROGRESS);

const anySpecialTasks = anyPass([
  homeInsurance,
  recentTransactions,
  smsConsent,
]);

const Renovation = ({config, onTaskComplete, tab}) => {
  const {facet} = useParams();
  const {project} = useToggledBorrowerProject();
  const projectId = propOr(null, 'id', project);
  const {loading, insurance, renovation} = useContext(ProjectTasksContext);
  const [insuranceTasks, renovationTasks, consentTasks] = useMemo(() => {
    const allTasks = [...insurance, ...renovation].filter((task) =>
      filterByTab(task, tab),
    );
    const iTasks = filter(homeInsurance, allTasks);
    const smsTasks = filter(smsConsent, allTasks);
    return [iTasks, reject(anySpecialTasks, allTasks), smsTasks];
  }, [insurance, renovation, tab]);

  const tasks = [...insuranceTasks, ...renovationTasks];

  const {
    onConfirmUpload,
    onShowPrompt,
    onRemoveDocument,
    loading: isSubmitting,
    removedDocument,
    uploadedDocument,
  } = useTaskHandlers({tasks, projectId, facet});

  const {onAcceptFiles} = useDocumentHandlers({tasks, projectId, facet});

  const {
    applicationState,
    onCloseRejection,
    onCloseAdditionalRequest,
    onShowRejection,
  } = useApplicationState();

  if (loading) {
    return <Loader label={false} />;
  }

  if (isEmpty([...tasks, ...consentTasks])) {
    return null;
  }

  return (
    <Modals
      tasks={renovationTasks}
      loading={isSubmitting}
      config={config}
      rejection={applicationState?.rejection}
      removeIds={removedDocument}
      addFilesData={uploadedDocument}
      additionalRequest={applicationState?.additionalRequest}
      onAcceptFiles={onAcceptFiles}
      onConfirmUpload={onConfirmUpload}
      onShowPrompt={onShowPrompt}
      onRemoveDocument={onRemoveDocument}
      onCloseRejection={onCloseRejection}
      onCloseAdditionalRequest={onCloseAdditionalRequest}>
      {Boolean(renovationTasks.length)
        ? renovationTasks.map((task) => (
            <TaskCard key={task.id}>
              <WizardUploadTask
                onRemoveFile={(value) =>
                  onShowPrompt({documentId: value, taskId: task.id})
                }
                onShowRejection={(documentId) =>
                  onShowRejection({documentId, taskId: task.id})
                }
                onTaskComplete={onTaskComplete}
                extra={getExtraDetails(task?.taskType)}
                showDetails
                task={task}
                wizard={RenovationWizard}
              />
            </TaskCard>
          ))
        : null}

      {Boolean(insuranceTasks.length) ? (
        <>
          {insuranceTasks.map((task) => (
            <TaskCard key={task.id}>
              <WizardUploadTask
                onRemoveFile={(value) =>
                  onShowPrompt({documentId: value, taskId: task.id})
                }
                onShowRejection={(documentId) =>
                  onShowRejection({documentId, taskId: task.id})
                }
                onTaskComplete={onTaskComplete}
                task={{...task, details: undefined}}
                wizard={InsuranceWizard}
              />
            </TaskCard>
          ))}
        </>
      ) : null}

      {Boolean(consentTasks.length) ? (
        <>
          {consentTasks.map((task) => (
            <TaskCard key={task?.id}>
              <WizardUploadTask
                onRemoveFile={(value) =>
                  onShowPrompt({documentId: value, taskId: task.id})
                }
                onShowRejection={(documentId) =>
                  onShowRejection({documentId, taskId: task.id})
                }
                onTaskComplete={onTaskComplete}
                extra={getExtraDetails(task?.taskType)}
                showDetails={false}
                task={{...task, details: undefined}}
                wizard={RenovationWizard}
              />
            </TaskCard>
          ))}
        </>
      ) : null}
    </Modals>
  );
};

Renovation.propTypes = {
  onTaskComplete: PropTypes.func.isRequired,
  config: PropTypes.object,
};

export default Renovation;
