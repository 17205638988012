import styled from '@emotion/styled';

import Flex from '../../Flex';

export const Container = styled(Flex)({
  borderRadius: 16,
  backgroundColor: 'white',
  flexDirection: 'column',
  border: '1px solid #D9E7F0',
});
