import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../../useToggledQuery';
import {GET_BORROWER_PROJECTS} from '../../../queries';

export default function useBorrowerProjects({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_BORROWER_PROJECTS,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching, refetch} = response;
  const projects = propOr([], 'borrowerProjects', data);

  return {fetch, projects, data, loading, error, refetching, refetch};
}
