import React from 'react';

const FindAProIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      className="first"
      clipRule="evenodd"
      d="M22.45 23.5L18.95 20C18.5667 20.2833 18.1708 20.4792 17.7625 20.5875C17.3542 20.6958 16.937 20.75 16.5109 20.75C15.3273 20.75 14.3213 20.3365 13.4928 19.5096C12.6643 18.6826 12.25 17.6784 12.25 16.4971C12.25 15.3157 12.6635 14.3125 13.4904 13.4875C14.3174 12.6625 15.3216 12.25 16.5029 12.25C17.6843 12.25 18.6875 12.6643 19.5125 13.4928C20.3375 14.3213 20.75 15.3273 20.75 16.5109C20.75 16.937 20.6958 17.3542 20.5875 17.7625C20.4792 18.1708 20.2833 18.5667 20 18.95L23.5 22.45L22.45 23.5ZM16.5 19.25C17.2833 19.25 17.9375 18.9875 18.4625 18.4625C18.9875 17.9375 19.25 17.2833 19.25 16.5C19.25 15.7167 18.9875 15.0625 18.4625 14.5375C17.9375 14.0125 17.2833 13.75 16.5 13.75C15.7167 13.75 15.0625 14.0125 14.5375 14.5375C14.0125 15.0625 13.75 15.7167 13.75 16.5C13.75 17.2833 14.0125 17.9375 14.5375 18.4625C15.0625 18.9875 15.7167 19.25 16.5 19.25ZM3 20V17.65C3 17.0333 3.14583 16.5083 3.4375 16.075C3.72917 15.6417 4.15 15.2833 4.7 15C5.48333 14.6167 6.50417 14.2542 7.7625 13.9125C9.02083 13.5708 10.3 13.4417 11.6 13.525C11.4667 13.7417 11.3417 13.9792 11.225 14.2375C11.1083 14.4958 11.0167 14.75 10.95 15C9.65 14.9833 8.51667 15.1375 7.55 15.4625C6.58333 15.7875 5.83333 16.0833 5.3 16.35C5.06667 16.4833 4.875 16.6625 4.725 16.8875C4.575 17.1125 4.5 17.3667 4.5 17.65V18.5H10.95C11.1333 18.7833 11.3 19.0458 11.45 19.2875C11.6 19.5292 11.7667 19.7667 11.95 20H3Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      className="first"
      clipRule="evenodd"
      d="M10.9492 11.375C9.84922 11.375 8.94922 11.0166 8.24922 10.3C7.54922 9.58328 7.19922 8.69162 7.19922 7.62495H6.94922C6.81589 7.62495 6.69922 7.57495 6.59922 7.47495C6.49922 7.37495 6.44922 7.25828 6.44922 7.12495C6.44922 6.99162 6.49922 6.87495 6.59922 6.77495C6.69922 6.67495 6.81589 6.62495 6.94922 6.62495H7.19922C7.19922 5.95828 7.36589 5.35828 7.69922 4.82495C8.03255 4.29162 8.46589 3.85828 8.99922 3.52495V4.49995C8.99922 4.59995 9.03672 4.68745 9.11172 4.76245C9.18672 4.83745 9.27422 4.87495 9.37422 4.87495C9.49089 4.87495 9.58255 4.83745 9.64922 4.76245C9.71589 4.68745 9.74922 4.59995 9.74922 4.49995V3.19995C9.88255 3.16662 10.0659 3.13745 10.2992 3.11245C10.5326 3.08745 10.7576 3.07495 10.9742 3.07495C11.1909 3.07495 11.4159 3.08745 11.6492 3.11245C11.8826 3.13745 12.0659 3.16662 12.1992 3.19995V4.49995C12.1992 4.59995 12.2326 4.68745 12.2992 4.76245C12.3659 4.83745 12.4576 4.87495 12.5742 4.87495C12.6742 4.87495 12.7617 4.83745 12.8367 4.76245C12.9117 4.68745 12.9492 4.59995 12.9492 4.49995V3.52495C13.4826 3.85828 13.9076 4.29162 14.2242 4.82495C14.5409 5.35828 14.6992 5.95828 14.6992 6.62495H14.9492C15.0826 6.62495 15.1992 6.67495 15.2992 6.77495C15.3992 6.87495 15.4492 6.99162 15.4492 7.12495C15.4492 7.25828 15.3992 7.37495 15.2992 7.47495C15.1992 7.57495 15.0826 7.62495 14.9492 7.62495H14.6992C14.6992 8.69162 14.3492 9.58328 13.6492 10.3C12.9492 11.0166 12.0492 11.375 10.9492 11.375ZM12.5992 9.24995C12.1992 9.66662 11.6492 9.87495 10.9492 9.87495C10.2492 9.87495 9.69922 9.66662 9.29922 9.24995C8.89922 8.83328 8.69922 8.29162 8.69922 7.62495H13.1992C13.1992 8.29162 12.9992 8.83328 12.5992 9.24995Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      className="second"
      clipRule="evenodd"
      d="M22.45 23.5L18.95 20C18.5667 20.2833 18.1708 20.4792 17.7625 20.5875C17.3542 20.6958 16.937 20.75 16.5109 20.75C15.3273 20.75 14.3213 20.3365 13.4928 19.5096C12.6643 18.6826 12.25 17.6784 12.25 16.4971C12.25 15.3157 12.6635 14.3125 13.4904 13.4875C14.3174 12.6625 15.3216 12.25 16.5029 12.25C17.6843 12.25 18.6875 12.6643 19.5125 13.4928C20.3375 14.3213 20.75 15.3273 20.75 16.5109C20.75 16.937 20.6958 17.3542 20.5875 17.7625C20.4792 18.1708 20.2833 18.5667 20 18.95L23.5 22.45L22.45 23.5ZM18.4625 18.4625C17.9375 18.9875 17.2833 19.25 16.5 19.25C15.7167 19.25 15.0625 18.9875 14.5375 18.4625C14.0125 17.9375 13.75 17.2833 13.75 16.5C13.75 15.7167 14.0125 15.0625 14.5375 14.5375C15.0625 14.0125 15.7167 13.75 16.5 13.75C17.2833 13.75 17.9375 14.0125 18.4625 14.5375C18.9875 15.0625 19.25 15.7167 19.25 16.5C19.25 17.2833 18.9875 17.9375 18.4625 18.4625Z"
      fill="currentcolor"
    />
  </svg>
);

export default FindAProIcon;
