import React, {Fragment} from 'react';

import {last} from 'lodash';

import {basic80, isMobile, successDark, warningDark} from '@renofi/theme';
import {Box, Flex, Icon, Text} from '@renofi/components-internal';
import {useAppraisalOrders} from '@renofi/api';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {getLatestDocuments} from '@renofi/utilities/src/appraisal';

import {ManageDocuments} from '../../../../../tasks-wizard';

import PastFutureDate from './PastFutureDate';
import {gridStyles, Order, Separator} from './styled';
import {getIntroTextByOrder} from './utils';

const NO_VALUE = 'To be confirmed';

const AppraisalOrder = () => {
  const {project} = useToggledBorrowerProject();
  const {id: projectId, timeline} = project || {};
  const {appraisalOrders} = useAppraisalOrders({
    variables: {projectId},
  });
  const appraisalOrder = last(appraisalOrders);
  const {
    inspectionAppointmentAt,
    inspectionCompletedAt,
    reportReceivedAt,
    submittedAt,
  } = appraisalOrder || {};

  const isCompleted = Boolean(timeline?.appraisalReportReviewFinishedAt);
  const iconColor = isCompleted ? successDark : warningDark;
  const inspectionDate = inspectionAppointmentAt || inspectionCompletedAt;
  const amcName = appraisalOrder?.amc?.name || NO_VALUE;

  const projectAppraisalDocs =
    project?.documents?.filter(
      (doc) =>
        (doc.documentType === 'appraisal' ||
          doc.documentType === 'completion_certificate') &&
        doc.status === 'accepted',
    ) || [];
  const latestGroupedDocs = getLatestDocuments(projectAppraisalDocs);
  const introText = getIntroTextByOrder(appraisalOrder, isCompleted);

  return (
    <>
      <Order isCompleted={isCompleted}>
        <Flex alignItems="center">
          <Icon name="helpClinic" color={iconColor} />

          <Text ml={2}>Appraisal order date:</Text>
          <Text color={basic80} ml={1}>
            {submittedAt ? <PastFutureDate value={submittedAt} /> : NO_VALUE}
          </Text>
        </Flex>

        <Separator isCompleted={isCompleted} />

        <Flex alignItems="center">
          <Icon name="calendarMonth" color={iconColor} />

          <Text ml={2}>Inspection date:</Text>
          <Text color={basic80} ml={1}>
            {inspectionDate ? (
              <PastFutureDate value={inspectionDate} />
            ) : (
              NO_VALUE
            )}
          </Text>
        </Flex>
        <Separator isCompleted={isCompleted} />

        {Boolean(reportReceivedAt) ? (
          <Fragment>
            <Flex alignItems="center">
              {isMobile() ? (
                <Box mr={2}>
                  <Icon name="calendarMonth" color={iconColor} />
                </Box>
              ) : null}

              <Text>Delivery date:</Text>
              <Text color={basic80} ml={1}>
                <PastFutureDate value={reportReceivedAt} />
              </Text>
            </Flex>
            <Separator isCompleted={isCompleted} />
          </Fragment>
        ) : null}

        <Flex alignItems="center">
          <Icon name="locationHome" color={iconColor} />

          <Text ml={2}>Appraiser:</Text>
          <Text color={basic80} mr={16} ml={1}>
            {amcName}
          </Text>
        </Flex>
      </Order>
      <Text p={24}>{introText}</Text>

      {isCompleted && latestGroupedDocs?.length ? (
        <Box px={24} mb={16}>
          <ManageDocuments
            isNestedModal={false}
            showDownloadButton={true}
            immutable
            customDocuments={latestGroupedDocs}
            containerCss={gridStyles}
          />
        </Box>
      ) : null}
    </>
  );
};

export default AppraisalOrder;
