import React, {useContext, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {Alert, Box, Card, Flex, Text} from '@renofi/components-internal';
import {Context as ConfigContext} from '@renofi/utilities/src/config';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {isTpoProcess} from '@renofi/utilities/src/application-process';
import {isMobile} from '@renofi/theme';

import {
  AdditionalDocuments,
  BorrowerDetails,
  ContractorV3,
  ProductConfirmation,
  Renovation,
  Appraisal,
  TASK_STATUSES,
} from '../../index';
import {TasksWizardContainer} from '../../../tasks-wizard';
import {context as ProjectTasksContext} from '../../../../lib/projectTasks';
import {MessageBox} from '../ProductConfirmation/styled';
import {getProductDetails} from '../ProductConfirmation/utils';
import AppraisalOrder from '../Renovation/components/AppraisalOrder';

import TasksTabs from './TasksTabs';

const TasksList = ({tab, onTabClick}) => {
  const {allTasks} = useContext(ProjectTasksContext);
  const config = useContext(ConfigContext);
  const {project} = useToggledBorrowerProject();
  const isRaas = propOr(false, 'raas', project);
  const projectId = propOr(null, 'id', project);
  const [consecutiveTaskIds, setConsecutiveTaskIds] = useState(new Set());
  const onTaskComplete = (taskId) => {
    const newConsecutiveTaskIds = new Set(consecutiveTaskIds);
    newConsecutiveTaskIds.add(taskId);
    setConsecutiveTaskIds(newConsecutiveTaskIds);
  };
  const tasks = allTasks.filter(
    (t) =>
      !t.taskType.includes('contractor') &&
      !t.taskType.includes('onboarding') &&
      !t.taskType.includes('home_insurance'),
  );
  const outstandingTasks = tasks?.filter((t) => t.status === 'outstanding');
  const completedTasks = tasks?.filter((t) => t.status !== 'outstanding');

  const productDetailsTask = useMemo(() => {
    return getProductDetails(tasks);
  }, [tasks]);

  const {productDetailsConfirmation, status} = productDetailsTask || {};
  const isTpoTask = isTpoProcess(
    productDetailsConfirmation?.applicationProcess,
  );
  const isProductDetailsCompleted = status === TASK_STATUSES.completed;
  const isProductDetailsProcessing = status === TASK_STATUSES.processing;

  const isSubmitted = isProductDetailsCompleted || isProductDetailsProcessing;
  const isTpoProductDetailsProcessing = isProductDetailsProcessing && isTpoTask;
  const isNonTpoProductDetailsProcessing =
    isProductDetailsProcessing && !isTpoTask;
  const facetProps = {isRaas, onTaskComplete, projectId, tab, tasks};

  return (
    <div>
      {isMobile() ? null : (
        <Box mt={24}>
          <TasksTabs tab={tab} onTabClick={onTabClick} project={project} />
        </Box>
      )}

      <Card pt={0} css={{borderTop: 'solid 1px #e5e5e5'}}>
        <TasksWizardContainer>
          <Flex justifyContent="center" width="100%" flexDirection="column">
            {tab === 'outstanding' ? (
              <Box mt={3}>
                {!isTpoProductDetailsProcessing &&
                !isSubmitted &&
                outstandingTasks?.length > 0 ? (
                  <Alert>
                    <Box>
                      Please take a moment to finalize your loan application
                      details by completing the tasks below.
                    </Box>
                  </Alert>
                ) : null}
                {isTpoProductDetailsProcessing ? (
                  <MessageBox p={0} alignItems="center" variant="warning">
                    Your RenoFi Advisor will review your details and contact you
                    to discuss the next steps. You are not quite ready to
                    schedule a final review call as some of your details are not
                    ready to share with the Lender.
                  </MessageBox>
                ) : null}
                {isNonTpoProductDetailsProcessing ? (
                  <MessageBox p={0} alignItems="center" variant="warning">
                    Your RenoFi Advisor will review your details and contact you
                    to discuss the next steps.
                  </MessageBox>
                ) : null}
              </Box>
            ) : null}

            {tab === 'outstanding' && !outstandingTasks?.length ? (
              <Flex justifyContent="center" py={124}>
                <Text>Nice work! You’ve completed all your tasks!</Text>
              </Flex>
            ) : null}

            {tab === 'completed' && !completedTasks?.length ? (
              <Flex justifyContent="center" py={124}>
                <Text>
                  No completed tasks yet. Please go to the outstanding tasks
                  section and start completing tasks.
                </Text>
              </Flex>
            ) : null}

            {tab === 'outstanding' || tab === 'completed' ? (
              <>
                <Appraisal config={config} {...facetProps} />
                <Renovation config={config} {...facetProps} />
                <AdditionalDocuments config={config} {...facetProps} />
                <ProductConfirmation {...facetProps} />
                <BorrowerDetails config={config} {...facetProps} />
              </>
            ) : null}

            {tab === 'appraisal' ? (
              <Box mx={-16}>
                <AppraisalOrder />
              </Box>
            ) : null}

            {tab === 'contractors' ? <ContractorV3 {...facetProps} /> : null}
          </Flex>
        </TasksWizardContainer>
      </Card>
    </div>
  );
};

TasksList.propTypes = {
  tab: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default TasksList;
