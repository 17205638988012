import React from 'react';

import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';
import BackIcon from '@renofi/icons/src/BackIcon';
import {dangerRegular} from '@renofi/theme';

import {Container, StyledTitle, IconContainer} from './styled';

const PageTitle = ({
  text,
  fontSize,
  backButton = true,
  onBackClick = noop,
  ...props
}) => {
  return (
    <Container {...props}>
      {backButton && (
        <IconContainer>
          <BackIcon
            color={dangerRegular}
            onClick={onBackClick}
            id="back-button"
          />
        </IconContainer>
      )}
      <StyledTitle fontSize={fontSize}>{text}</StyledTitle>
    </Container>
  );
};

PageTitle.propTypes = {
  text: PropTypes.string,
  backButton: PropTypes.bool,
  onBackClick: PropTypes.func,
};

export default PageTitle;
