import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {
  WizardUploadTask,
  useApplicationState,
  useTaskHandlers,
} from 'modules/project';

import {filterByTab} from '../../filterByTab';
import {TaskCard} from '../styled';

import AdditionalDocumentsWizard from './components/Wizard';

const AdditionalDocuments = ({onTaskComplete, projectId, tab}) => {
  const {facet} = useParams();
  const {additionalDocuments} = useContext(ProjectTasksContext);
  const tasks = additionalDocuments.filter((task) => filterByTab(task, tab));

  const {onShowPrompt} = useTaskHandlers({
    tasks,
    projectId,
    facet,
  });
  const {onShowRejection} = useApplicationState();

  if (tasks.length === 0) return null;

  return (
    <>
      {tasks.map((task) => {
        return (
          <TaskCard key={task.id}>
            <WizardUploadTask
              onRemoveFile={(value) =>
                onShowPrompt({documentId: value, taskId: task.id})
              }
              onShowRejection={(documentId) =>
                onShowRejection({documentId, taskId: task.id})
              }
              onTaskComplete={onTaskComplete}
              showDetails
              task={task}
              wizard={AdditionalDocumentsWizard}
              wizardProps={{
                header: 'Additional documents',
              }}
            />
          </TaskCard>
        );
      })}
    </>
  );
};

AdditionalDocuments.propTypes = {
  onTaskComplete: PropTypes.func,
  projectId: PropTypes.string.isRequired,
};

export default AdditionalDocuments;
