import React, {useState, useEffect, useCallback, useContext} from 'react';

import {useHistory, useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import lazy from 'lib/asyncComponent';
import {Help, StickyHeader} from 'modules/layout';

import {scrollToTop} from '@renofi/utils';
import {Flex, Loader} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {isMobile} from '@renofi/theme';

import TasksList from '../TasksList/TasksList';
import TasksTabs from '../TasksList/TasksTabs';

import {StyledContainer} from './styled';
import {TASK_ID_KEY} from './constants';

const Nothing = lazy(
  () => import(/* webpackChunkName: "no-checklist" */ '../Nothing'),
);

const Project = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get('tab');
  const [tab, setTab] = useState(tabParam || 'outstanding');
  const history = useHistory();
  const storage = useContext(StorageContext);
  const {url} = useRouteMatch();

  const {project, loading: loadingBorrowerProject} =
    useToggledBorrowerProject();

  const {tasksByStatus, loading} = useContext(ProjectTasksContext);
  const [scrollShadow, setScrollShadow] = useState(false);
  const projectId = project?.id;

  const viewTask = (task) => {
    history.push(`/${projectId}/tasks/${task.id}`);
  };

  const handleScroll = useCallback(() => {
    const value = window.pageYOffset;
    if (value > 30 && !scrollShadow) {
      setScrollShadow(true);
    } else if (value < 30) {
      setScrollShadow(false);
    }
  }, [window.pageYOffset, scrollShadow]);

  useEffect(() => {
    if (tabParam) setTab(tabParam);
  }, [tabParam]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const firstUploadTaskId = storage.getItem(TASK_ID_KEY);
    const hasOneDocument = project?.documents?.length === 1;

    if (firstUploadTaskId && hasOneDocument) {
      const {documentType, fileName} = project?.documents[0];
      sendEvent('Secure/One-Document-Uploaded', {
        name: `${documentType} - ${fileName}`,
      });
    }
  }, [projectId]);

  function onTabClick(value) {
    scrollToTop();
    setTab(value);
    history.push(`${url}?tab=${value}`);
  }

  if (loading || loadingBorrowerProject) {
    return <Loader />;
  }

  if (!projectId) {
    return <Nothing />;
  }

  return (
    <>
      <Flex justifyContent="center" width="100%">
        <StyledContainer narrow>
          {isMobile() ? (
            <StickyHeader backButton={false}>
              <TasksTabs
                tab={tab}
                onTabClick={onTabClick}
                project={project}
                mobile
                borderBottom
              />
            </StickyHeader>
          ) : null}
          <>
            <TasksList
              tasks={tasksByStatus}
              onClick={viewTask}
              onTabClick={onTabClick}
              tab={tab}
            />
          </>
          <Help />
        </StyledContainer>
      </Flex>
    </>
  );
};

Project.propTypes = {
  consecutiveTaskIds: PropTypes.arrayOf(PropTypes.string),

  user: PropTypes.object,
  storage: PropTypes.object,
};

export default React.memo(Project);
