import styled from '@emotion/styled';

import {
  actionDark,
  actionRegular,
  basic20,
  basic55,
  basic75,
  border,
  dangerDark,
  dangerRegular,
} from '@renofi/theme';

import {Box} from '../index';
import Flex from '../Flex';

export const TabsContainer = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
  },
  ({mobile}) => ({
    marginBottom: mobile ? -5 : -3,
    width: mobile ? '100%' : 'auto',
    justifyContent: mobile ? 'space-between' : 'flex-start',
  }),
);

export const Tab = styled.div(({isActive, borderBottom, mobile, red}) => ({
  padding: red ? (mobile ? '16px 8px' : 16) : '8px 16px',
  marginRight: mobile ? 0 : '8px',
  width: mobile ? '100%' : 177,
  cursor: 'pointer',
  backgroundColor: 'white',
  borderTopLeftRadius: 2,
  borderTopRightRadius: 2,
  [borderBottom ? 'borderBottom' : 'borderTop']:
    `2px solid ${isActive ? (red ? dangerRegular : actionRegular) : 'transparent'}`,
  transition: 'all 0.2s ease',
  '&:nth-of-type(1)': {
    borderLeft: `solid 1px ${border}`,
  },
  '&:hover': {
    [borderBottom ? 'borderTopColor' : 'borderTopColor']: isActive
      ? red
        ? dangerRegular
        : actionRegular
      : basic20,
  },
  position: isActive ? 'relative' : 'static',
}));

export const MainText = styled.div(
  {
    marginBottom: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ({bold, error, red, mobile}) => ({
    fontSize: mobile ? 14 : 16,
    fontFamily: bold ? 'Averta-Bold' : 'Averta',
    color: error ? dangerDark : bold ? (red ? basic75 : actionDark) : basic75,
  }),
);

export const SubText = styled.div({
  fontSize: 12,
  color: basic55,
  marginBottom: '2px',
});

export const ButtonWrapper = styled(Flex)({
  width: 40,
  height: 56,
  paddingLeft: '8px',
  alignItems: 'center',
  position: 'relative',
});

export const OptionsWrapper = styled(Box)({
  position: 'absolute',
  width: 'auto',
  background: 'white',
  padding: '8px',
  top: 40,
  left: 9,
  borderRadius: 3,
  boxShadow: '1px 1px 6px 0px #00000038',
  zIndex: 100,
});
