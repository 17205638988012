import {taskTypes} from '@renofi/utilities/src/enums';

export const transformAddressToDbSchema = (address) => {
  const {
    address: propertyStreetAddressOne,
    secondAddress: propertyStreetAddressTwo,
    city: propertyCity,
    county: propertyCounty,
    state: propertyState,
    zipCode: propertyZipCode,
  } = address;

  return {
    propertyStreetAddressOne,
    propertyStreetAddressTwo,
    propertyCity,
    propertyCounty,
    propertyState,
    propertyZipCode,
  };
};

export const getResidencyAddressFromTasks = (allTasks, borrowerRole) => {
  const {personalInfoAndResidency} =
    allTasks.find(
      (task) => task.taskType === taskTypes.personal_info_and_residency,
    ) || {};
  const {city, state, streetAddressOne, streetAddressTwo, county, zipCode} =
    personalInfoAndResidency?.borrowerResidentialAddresses?.find(
      (address) => address?.borrowerRole === borrowerRole,
    ) || {};
  return {
    streetAddressOne,
    streetAddressTwo,
    city,
    state,
    county,
    zipCode,
  };
};

export const getPropertyAddressFromProject = (project) => {
  const {city, state, streetAddressOne, streetAddressTwo, county, zipCode} =
    project || {};
  return {
    streetAddressOne,
    streetAddressTwo,
    city,
    state,
    county,
    zipCode,
  };
};
