import React, {Fragment, isValidElement, useState} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';

import {Box, Flex, Icon, Link, TaskStatus} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {validateUuidProp} from '@renofi/utilities/src/react';
import {basic55} from '@renofi/theme';
import {hasCoBorrowerInProject} from '@renofi/utilities';

import {
  ButtonWraper,
  Children,
  Details,
  Title,
  Content,
  Wrapper,
  Break,
  ToggleDetails,
} from './styled';

const RENOVATION_CONTRACT = 'renovation_contract';

const COBORROWER_TASK_TYPES = [
  'assets',
  'employment_history',
  'government_issued_id',
  'income',
  'personal_info_and_residency',
  'real_estate_owned',
  'soft_credit_check',
  'identity_verification',
];

const TaskItem = ({
  button,
  showDetails: propsShowDetails = true,
  skipExtraDetails,
  children,
  task,
  customTaskStatus,
  ...props
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const {project} = useToggledBorrowerProject();

  const {allDocumentsReviewed, taskType} = task || {};
  //TODO: remove this check when task.details will sent as null from Backend
  const shouldHideDetails =
    !propsShowDetails || taskType === RENOVATION_CONTRACT;

  const details = shouldHideDetails ? null : props?.details || task?.details;
  const status = props?.status || task?.status;
  const title = props?.title || task?.title;

  const isInProgress = status !== 'outstanding';
  const isCompleted = status === 'completed';
  const showButton = isValidElement(button) && !isCompleted;
  const hasCoBorrower = hasCoBorrowerInProject(project);
  const showIcon = hasCoBorrower && COBORROWER_TASK_TYPES.includes(taskType);

  if (!task || isEmpty(task)) {
    return null;
  }

  return (
    <Wrapper data-testid={taskType} data-task-type={taskType} py={3}>
      {status !== 'outstanding' ? (
        <TaskStatus
          text={customTaskStatus}
          allDocumentsReviewed={allDocumentsReviewed}
          status={status}
          mb="8px"
        />
      ) : null}
      <Content width={1}>
        <Flex maxWidth={['100%', '80%']} alignItems="center">
          <Title>{title}</Title>
          {showIcon && (
            <Box ml={2}>
              <Icon color={basic55} name="team" />
            </Box>
          )}
        </Flex>

        {showButton && <ButtonWraper>{button}</ButtonWraper>}

        <Break />

        {Boolean(details) ? (
          <Fragment>
            <Details fontSize={[14, 15]} color={basic55}>
              {details}
            </Details>
            <Break />
          </Fragment>
        ) : null}

        {isInProgress && !skipExtraDetails && (
          <ToggleDetails>
            <Link
              css={{fontSize: 14}}
              onClick={() => setShowDetails(!showDetails)}>
              <Box mb={showDetails ? 18 : 0} mt="8px">
                {showDetails ? 'Hide details' : 'Show details'}
              </Box>
            </Link>
          </ToggleDetails>
        )}

        <Break />
        {isInProgress && showDetails ? <Children>{children}</Children> : null}
      </Content>
    </Wrapper>
  );
};

TaskItem.propTypes = {
  button: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  details: PropTypes.string,
  task: PropTypes.shape({
    details: PropTypes.string,
    id: validateUuidProp,
    status: PropTypes.string,
    title: PropTypes.string,
  }),
  showDetails: PropTypes.bool,
  status: PropTypes.string,
  title: PropTypes.string,
  customTaskStatus: PropTypes.string,
  skipExtraDetails: PropTypes.bool,
};

export default TaskItem;
