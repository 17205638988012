import React, {useContext, useMemo} from 'react';

import {Route, Switch} from 'react-router-dom';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {TASK_STATUSES} from 'modules/project';

import {filterByTab} from '../../filterByTab';
import {TaskCard} from '../styled';

import Task from './components/Task';
import {
  sortTasks,
  getProductDetails,
  getProductCertificationDetails,
} from './utils';

const Wizard = lazy(
  () => import(/* webpackChunkName: "details-wizard" */ './components/Wizard'),
);

const ProductConfirmation = ({tab}) => {
  const {productConfirmation = []} = useContext(ProjectTasksContext);
  const tasks = productConfirmation.filter((task) => filterByTab(task, tab));

  const sortedTasks = useMemo(() => {
    return sortTasks(tasks);
  }, [JSON.stringify(tasks)]);

  const productDetailsTask = useMemo(() => {
    return getProductDetails(productConfirmation);
  }, [tasks]);

  const certificateAcknowledgementTask =
    getProductCertificationDetails(productConfirmation);
  const isCertificateAcknowledgementCompleted = Boolean(
    certificateAcknowledgementTask?.completedAt,
  );

  const {status} = productDetailsTask || {};
  const isProductDetailsCompleted = status === TASK_STATUSES.completed;

  if (!tasks.length) return null;

  return (
    <>
      {sortedTasks.map((task) => (
        <TaskCard key={task.id}>
          <Task
            {...task}
            isProductDetailsCompleted={isProductDetailsCompleted}
            isCertificateAcknowledgementCompleted={
              isCertificateAcknowledgementCompleted
            }
          />
        </TaskCard>
      ))}
      <Switch>
        <Route
          path={`/:projectId/tasks/:facet(product_confirmation)/:taskId?/:step?`}>
          <Wizard tasks={tasks} />
        </Route>
      </Switch>
    </>
  );
};

export default ProductConfirmation;
