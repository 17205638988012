import React from 'react';

import {propEq, reject} from 'ramda';

import {sendEvent} from '@renofi/analytics';
import DesignIcon from '@renofi/icons/src/DesignIcon';
import TasksIcon from '@renofi/icons/src/TasksIcon';
import FindAProIcon from '@renofi/icons/src/FindAProIcon';
import LenderIcon from '@renofi/icons/src/LenderIcon';
import RenovateIcon from '@renofi/icons/src/RenovateIcon';
import JourneyIcon from '@renofi/icons/src/JourneyIcon';

export function getSideMenu(
  totalTasks,
  {isRaas, isRenoFiDesignEnabled, isFindContractorEnabled},
) {
  let menu = [
    {
      id: 'design',
      title: 'Renovation Design',
      icon: <DesignIcon />,
      onClick: () => {
        sendEvent('Secure/RenoFi-Design-Clicked');
        window.open('https://www.renofi.com/design', '_blank');
      },
    },
    {
      id: 'tasks',
      title: 'Tasks',
      icon: <TasksIcon />,
      badge: totalTasks || null,
    },
    {
      id: 'find-a-pro',
      title: 'Find a pro',
      icon: <FindAProIcon />,
    },
    {
      id: 'lender',
      title: 'Apply with Lender',
      icon: <LenderIcon />,
    },
    {
      id: 'renovation-progress',
      title: 'Renovate',
      icon: <RenovateIcon />,
    },
    {
      id: 'finished',
      title: 'Finish the journey',
      icon: <JourneyIcon />,
    },
  ];

  if (!isRenoFiDesignEnabled) {
    menu = reject(propEq('id', 'design'), menu);
  }

  if (!isFindContractorEnabled) {
    menu = reject(propEq('id', 'find-a-pro'), menu);
  }

  if (isRaas) {
    menu = reject(propEq('id', 'lender'), menu);
  }

  return menu;
}
