import React from 'react';

import PropTypes from 'prop-types';

import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {Title} from './styled';

const StatementsTitle = ({assetType, borrowerName}) => {
  const extraCopy =
    assetType === 'retirement'
      ? ' and a copy of the Terms of Withdrawal'
      : null;
  return (
    <Title mb={3}>
      Next, upload the 2 most recent statements {extraCopy} for {borrowerName}
      ’s <strong>{humanizeSnakeCase(assetType)} accounts.</strong>
    </Title>
  );
};

StatementsTitle.propTypes = {
  assetType: PropTypes.string,
  borrowerName: PropTypes.string,
};

export default StatementsTitle;
