/* eslint-disable max-lines */
import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import {find, propEq} from 'ramda';
import {v4} from 'uuid';
import {useCurrentTask} from 'modules/project/hooks';

import {
  GET_PROJECT_TASKS,
  useProjectTasks,
  useSubmitContractorInformation,
  useToggledBorrowerProject,
  useValidateEmail,
  useValidatePhoneNumber,
} from '@renofi/graphql';
import {AddressField, Box} from '@renofi/components-internal';
import {
  DEFAULT_PLACE_FIELDS,
  getStateFromZipCode,
  isEmail,
} from '@renofi/utilities';
import useDebounce from '@renofi/utilities/src/useDebounce';

import {EMPTY_CONTRACTOR, NEW_CONTRACTOR} from '../../constants';
import {
  hasContractorChanged,
  getContractorById,
  getRequiredMessage,
  hasMissingFields,
  getContractorProps,
} from '../../utils';
import {Title} from '../../../../../layout/components/StickyHeader/styled';

import {AutocompleteWrapper, Button, FieldsWrapper} from './styled';
import AutocompleteFields from './AutocompleteFields';
import useShowSavedModal from './useShowSavedModal';
import SaveFinishedModal from './SaveFinishedModal';
import ConfirmEditModal from './ConfirmEditModal';
import useConfirmEditModal from './useConfirmEditModal';
import Documents from './Documents';

const AddEditContractor = ({onClickDelete, hasNoRenoTasks = false}) => {
  const {contractorId} = useParams();
  const isEditing = contractorId !== NEW_CONTRACTOR;
  const history = useHistory();

  const {
    fetch,
    loading: isValidatingEmail,
    valid: isEmailValid,
  } = useValidateEmail({lazy: true});
  const {
    fetch: fetchPhone,
    loading: isValidatingPhoneNumber,
    valid: isPhoneNumberValid,
  } = useValidatePhoneNumber({lazy: true});
  const validateEmail = useDebounce(fetch, 750);
  const validatePhoneNumber = useDebounce(fetchPhone, 750);

  const {project} = useToggledBorrowerProject();
  const projectId = project?.id;
  const variables = {projectId};
  const refetchQueries = [
    {
      query: GET_PROJECT_TASKS,
      variables,
    },
  ];

  const {loading: isSubmitting, submitContractorInformation} =
    useSubmitContractorInformation({
      refetchQueries,
    });

  const {loading: isFetching, tasks} = useProjectTasks({
    variables,
  });
  const task = find(propEq('taskType', 'contractor_info'))(tasks);
  const loading =
    isFetching || isSubmitting || isValidatingEmail || isValidatingPhoneNumber;

  const {sendEvent} = useCurrentTask({taskId: task?.id});
  const [isDirty, setIsDirty] = useState(false);
  const {setShowSavedModal} = useShowSavedModal();
  const {setShowConfirmEditModal} = useConfirmEditModal();
  const [contractor, setContractor] = useState(() => {
    if (!isEditing) {
      return EMPTY_CONTRACTOR;
    }

    return getContractorById(contractorId)(task);
  });
  const isDeleted = Boolean(contractor?.deletedAt);
  const isDdRequested = Boolean(contractor?.ddRequestedAt);
  const invalidFields = hasMissingFields({
    contractor,
    isEmailValid,
    isPhoneNumberValid,
  });

  useEffect(() => {
    const {email, phoneNumber} = contractor || {};

    if (!task?.contractorInformations?.length) {
      sendEvent('Secure/Task-Started');
    }

    if (email) {
      validateEmail({variables: {email}});
    }

    if (phoneNumber) {
      validatePhoneNumber({variables: {phoneNumber}});
    }
  }, []);

  const onClickYes = () => {
    setContractor(EMPTY_CONTRACTOR);
    setTimeout(() => setIsDirty(false), 100);
    // Fudge 'cos NumericFormat in MoneyField trigger a false positive "change" event.
  };

  const onUpdate = (key, value) => {
    setIsDirty(true);
    setContractor((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onUpdateEmail = (email) => {
    onUpdate('email', email);
    if (email && isEmail(email)) {
      validateEmail({variables: {email}});
    }
  };

  const onUpdatePhoneNumber = (phoneNumber) => {
    onUpdate('phoneNumber', phoneNumber);
    if (phoneNumber) {
      validatePhoneNumber({variables: {phoneNumber}});
    }
  };

  const onUpdateZipCode = (zipCode) => {
    const data = getStateFromZipCode(zipCode);
    const state = Boolean(data) ? data.code : null;

    setIsDirty(true);
    setContractor((s) => ({
      ...s,
      zipCode,
      state,
    }));
  };

  const onSelectPlace = (place) => {
    const {address: streetAddress, city, details, state, zipCode} = place;
    const {
      formatted_phone_number: phoneNumber,
      name: businessName,
      website,
    } = details || {};

    if (phoneNumber) {
      validatePhoneNumber({variables: {phoneNumber}});
    }

    setIsDirty(true);
    setContractor((s) => ({
      ...s,
      businessName,
      city,
      phoneNumber,
      state,
      streetAddress,
      website,
      zipCode,
    }));
  };

  const onSubmit = async () => {
    const prev = getContractorById(contractorId)(task);
    const isGcChanged = isEditing && hasContractorChanged(prev, contractor);

    // Catch cases where enough fields are changed it MIGHT be a new GC.
    if (isGcChanged) {
      return setShowConfirmEditModal(true);
    }

    await saveContractor();
  };

  const onFinishReplace = () => {
    setIsDirty(false);
    setShowSavedModal(true);
  };

  const saveContractor = async () => {
    const contractorInformation = getContractorProps(contractor);
    const id = isEditing ? contractor.id : v4();

    const rsp = await submitContractorInformation({
      variables: {
        id,
        contractorInformation,
        taskId: task?.id,
      },
    });

    if (!rsp.errors) {
      setIsDirty(false);
      setShowSavedModal(true);
    }
  };

  const goBack = () => {
    history.push(`/${projectId}/tasks`);
  };

  return (
    <Fragment>
      <Box p={[0, 24]}>
        <Title
          fontSize={32}
          mb={24}
          mt={[24, 0]}
          css={{marginLeft: '-10px !important'}}
          role="sticky-header-title"
          text={contractor?.businessName || 'Contractor details'}
          backButton
          onBackClick={goBack}
        />
        {contractor?.id && hasNoRenoTasks && (
          <Documents
            projectId={projectId}
            contractor={contractor}
            task={task}
          />
        )}
        <AutocompleteWrapper>
          <AddressField
            active
            disabled={isDdRequested || isDeleted}
            error={
              isDirty ? getRequiredMessage('businessName', invalidFields) : null
            }
            label="Business name"
            leftIcon="glass"
            name="contractor"
            placeFields={[
              ...DEFAULT_PLACE_FIELDS,
              'formatted_phone_number',
              'international_phone_number',
              'types',
              'website',
            ]}
            placeTypes={['establishment']}
            placeholder="Enter a business name"
            primary={false}
            onClickOutside={(v) => onUpdate('businessName', v)}
            onManual={(v) => onUpdate('businessName', v)}
            onSelect={onSelectPlace}
            value={contractor?.businessName}
          />
        </AutocompleteWrapper>
        <AutocompleteFields
          contractor={contractor}
          disabled={isDdRequested || isDeleted}
          invalidFields={invalidFields}
          isDirty={isDirty}
          isEditing={isEditing}
          onUpdatePhoneNumber={onUpdatePhoneNumber}
          onUpdateEmail={onUpdateEmail}
          onUpdateZipCode={onUpdateZipCode}
          onUpdate={onUpdate}
        />

        {!isDeleted && !isDdRequested ? (
          <FieldsWrapper
            pt={4}
            alignItems="flex-end"
            justifyContent={isEditing ? 'space-between' : 'flex-end'}
            width={1}>
            {isEditing ? (
              <Button
                medium
                onClick={() => onClickDelete(contractor)}
                variant="dangerVibrant">
                Delete contractor
              </Button>
            ) : null}

            <Button
              disabled={Boolean(invalidFields?.length) || !isDirty || loading}
              medium
              onClick={onSubmit}>
              Save contractor details
            </Button>
          </FieldsWrapper>
        ) : null}
      </Box>

      <ConfirmEditModal
        contractor={contractor}
        onConfirmEdit={saveContractor}
        onFinishReplace={onFinishReplace}
        taskId={task?.id}
      />

      <SaveFinishedModal onYes={onClickYes} task={task} />
    </Fragment>
  );
};

AddEditContractor.propTypes = {
  onClickDelete: PropTypes.func,
};

export default AddEditContractor;
