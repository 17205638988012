import React, {useContext, useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {TabsV2} from '@renofi/components-internal';

import {filterByTab} from '../../filterByTab';
import {context as ProjectTasksContext} from '../../../../lib/projectTasks';

const TasksTabs = ({project, tab, onTabClick, mobile, borderBottom}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get('tab');
  const [forceTab] = useState(tabParam);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isXsScreen = windowWidth < 420;
  const {allTasks, contractor} = useContext(ProjectTasksContext);
  const appraisalDocuments =
    project?.documents?.filter(
      (doc) =>
        (doc.documentType === 'appraisal' ||
          doc.documentType === 'completion_certificate') &&
        doc.status === 'accepted',
    ) || [];
  const tasks = allTasks.filter(
    (t) =>
      !t.taskType.includes('contractor') &&
      !t.taskType.includes('onboarding') &&
      !t.taskType.includes('home_insurance'),
  );
  const outstandingTasks = tasks?.filter((t) => t.status === 'outstanding');
  const contractorDone = contractor?.filter((t) => t.status !== 'outstanding');

  const tabItems = [
    {
      label: 'Outstanding',
      value: 'outstanding',
      ...(outstandingTasks.length ? {badge: outstandingTasks.length} : {}),
    },
    {
      label: `Complete${!isXsScreen ? ' (' + tasks?.filter((t) => filterByTab(t, 'completed')).length + ')' : ''}`,
      value: 'completed',
    },
    ...(forceTab === 'appraisal' || appraisalDocuments.length
      ? [
          {
            label: `Appraisal${!isXsScreen && appraisalDocuments.length ? ` (${appraisalDocuments.length})` : ''}`,
            value: 'appraisal',
          },
        ]
      : []),
    ...(contractor?.length
      ? [
          {
            label: `Contractors${!isXsScreen && contractorDone.length ? ` (${contractorDone.length})` : ''}`,
            value: 'contractors',
            badge: contractor?.filter((t) => t.status === 'outstanding').length,
          },
        ]
      : []),
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <TabsV2
      borderBottom={borderBottom}
      mobile={mobile}
      selected={tab}
      items={tabItems}
      onClickTab={onTabClick}
      red
    />
  );
};

TasksTabs.propTypes = {
  project: PropTypes.object,
  tab: PropTypes.string,
  onTabClick: PropTypes.func,
  mobile: PropTypes.bool,
  borderBottom: PropTypes.bool,
};

export default TasksTabs;
