import styled from '@emotion/styled';

import {Box, Flex, Text} from '@renofi/components-internal';
import {basic80, maxSmall, minSmall} from '@renofi/theme';

export const Title = styled(Text)({
  fontSize: 18,
  lineHeight: '20px',
  [maxSmall]: {
    fontSize: 16,
    lineHeight: '18px',
    marginBottom: '4px',
  },
  color: basic80,
});

export const Details = styled(Text)({
  width: '79%',
  [maxSmall]: {
    width: '100%',
    marginTop: '8px',
  },
});

export const ButtonWraper = styled(Box)({
  [maxSmall]: {
    marginTop: 16,
    order: 99,
  },
});

export const Content = styled(Flex)({
  flexDirection: 'row',
  flexWrap: 'wrap',

  [maxSmall]: {
    flexDirection: 'column',
    flexWrap: 'unset',
  },
  [minSmall]: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const Wrapper = styled(Box)({
  paddingLeft: 24,
  paddingRight: 24,

  [maxSmall]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
});

export const Break = styled.div({
  flexBasis: '100%',
  height: 0,
  [maxSmall]: {
    display: 'none',
    visibility: 'hidden',
  },
});

export const Children = styled(Flex)({
  flexDirection: 'column',
  width: '100%',
});

export const ToggleDetails = styled(Box)();
