import React, {Fragment, useMemo, useState} from 'react';

import {filter, find, isNil, pipe, prop, propEq, propOr} from 'ramda';
import {Route, Switch, useHistory} from 'react-router-dom';

import {Box, Button, Loader, Modal, Text} from '@renofi/components-internal';
import {
  GET_PROJECT_TASKS,
  useProjectTasks,
  useRemoveContractorInformation,
} from '@renofi/graphql';
import {validateUuidProp} from '@renofi/utilities/src/react';

import AddEditContractor from './components/AddEditContractor';
import Table from './components/Table';
import {NEW_CONTRACTOR, UUID_V4_REGEX} from './constants';

const getNonDeletedItems = pipe(
  propOr([], 'contractorInformations'),
  filter(pipe(prop('deletedAt'), isNil)),
);

const ContractorV3 = ({projectId}) => {
  const history = useHistory();
  const variables = {projectId};

  const [contractorToDelete, setContractorToDelete] = useState(null);
  const {loading: isRemoving, removeContractorInformation} =
    useRemoveContractorInformation({
      refetchQueries: [
        {
          query: GET_PROJECT_TASKS,
          variables,
        },
      ],
    });
  const {loading, tasks} = useProjectTasks({variables});

  const hasNoRenoTasks = !Boolean(
    tasks.find((t) => t.taskType === 'renovation_contract'),
  );

  const task = useMemo(
    () => find(propEq('taskType', 'contractor_info'), tasks),
    [loading, tasks],
  );
  const contractors = getNonDeletedItems(task);

  const onRemoveContractor = async () => {
    const rsp = await removeContractorInformation({
      variables: {
        id: contractorToDelete?.id,
      },
    });

    setContractorToDelete(null);
    if (!rsp.errors) {
      history.push(`/${projectId}/tasks/contractor`);
    }
  };

  if (isNil(task)) return null;

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={`/:projectId/tasks/:facet(contractor)/:contractorId(${UUID_V4_REGEX}|${NEW_CONTRACTOR})`}>
          <AddEditContractor
            onClickDelete={setContractorToDelete}
            hasNoRenoTasks={hasNoRenoTasks}
          />
        </Route>
        <Route>
          <Table
            contractors={contractors}
            onClickDelete={setContractorToDelete}
            task={task}
          />
        </Route>
      </Switch>
      {Boolean(contractorToDelete) ? (
        <Modal acceptLabel="Yes, remove this contractor" show>
          <Box p={4}>
            <Text textAlign="center" fontSize={24}>
              Are you sure you want to remove {contractorToDelete?.businessName}{' '}
              from the project?
            </Text>
          </Box>
          <Button
            block
            medium
            disabled={isRemoving}
            onClick={onRemoveContractor}>
            Yes, remove this contractor
          </Button>
          <Box width={1} mt={3}>
            <Button
              block
              medium
              onClick={() => setContractorToDelete(null)}
              variant="secondary">
              Cancel
            </Button>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

ContractorV3.propTypes = {
  projectId: validateUuidProp,
};

export default ContractorV3;
