import React, {Fragment, useContext} from 'react';

import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';

import {taskTypes} from '@renofi/utilities/src/enums';
import {useToggledBorrowerProject} from '@renofi/graphql';

import {WizardUploadTask} from '../Task';
import {TaskCard} from '../styled';

import SoftCreditTask from './components/SoftCredit/Task';
import ExtraDetails from './components/ExtraDetails';
import {IdentityVerificationWizard} from './components/IdentityVerificationWizard';
import EditTaskButton from './components/EditTaskButton';

const PersonalInfoAndResidencyTask = lazy(
  () =>
    import(
      /* webpackChunkName: "personalInfo-task" */ './components/PersonalInfoAndResidency/Task'
    ),
);

const Borrowers = ({onTaskComplete, showCoBorrowerIcon, tasks = []}) => {
  const {project} = useToggledBorrowerProject();
  const {borrowerInformation = []} = useContext(ProjectTasksContext);

  const softCreditId = borrowerInformation?.find(
    (task) => task.taskType === taskTypes.soft_credit_check,
  )?.id;

  return Boolean(tasks?.length) ? (
    <>
      {tasks.map((task) => (
        <TaskCard key={task.id}>
          {(() => {
            switch (task.taskType) {
              case taskTypes.personal_info_and_residency:
                return (
                  <PersonalInfoAndResidencyTask
                    showCoBorrowerIcon={showCoBorrowerIcon}
                    softCreditId={softCreditId}
                    task={task}
                  />
                );
              case taskTypes.soft_credit_check:
                return <SoftCreditTask {...task} project={project} />;
              case taskTypes.identity_verification:
                return (
                  <IdentityVerificationWizard
                    onTaskComplete={onTaskComplete}
                    task={task}
                  />
                );
              default:
                return (
                  <WizardUploadTask
                    extra={ExtraDetails}
                    customAddEditButton={EditTaskButton}
                    onTaskComplete={onTaskComplete}
                    showCoBorrowerIcon={showCoBorrowerIcon}
                    showDetails
                    showTaskDocuments={false}
                    task={task}
                  />
                );
            }
          })()}
        </TaskCard>
      ))}
    </>
  ) : null;
};

Borrowers.propTypes = {
  onTaskComplete: PropTypes.func,
  showCoBorrowerIcon: PropTypes.bool,
  tasks: PropTypes.array,
  tab: PropTypes.string,
};

export default Borrowers;
