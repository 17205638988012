import styled from '@emotion/styled';

import {Box, PageTitle} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

const TOP_OFFSET_IMPERSONATION = 60;
const TOP_OFFSET_MOBILE = 70;
const TOP_OFFSET_MOBILE_IMPERSONATION =
  TOP_OFFSET_IMPERSONATION + TOP_OFFSET_MOBILE;

export const Blank = styled(Box)(({left}) => ({
  background: '#f5fbfe',
  width: 30,
  height: '100%',
  position: 'relative',
  [left ? 'marginLeft' : 'marginRight']: -30,
  [maxSmall]: {
    display: 'none',
  },
}));

export const Wrapper = styled.div(({impersonation, scrollShadow}) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 100,
  position: 'sticky',
  top: scrollShadow && impersonation ? TOP_OFFSET_IMPERSONATION : 0,
  zIndex: 3,
  transition: 'all 100ms ease',
  boxShadow: scrollShadow ? '0px 6px 7px 0px rgb(0 0 0 / 12%)' : 'none',
  background: '#f5fbfe',

  [maxSmall]: {
    background: 'white',
    width: 'calc(100% + 16px)',
    height: 60,
    margin: '0 -16px',
    top:
      scrollShadow && impersonation
        ? TOP_OFFSET_MOBILE_IMPERSONATION
        : TOP_OFFSET_MOBILE,
  },
}));

export const Title = styled(PageTitle)({
  width: '100%',
  flex: 1,
  fontSize: 20,
  [maxSmall]: {
    marginLeft: 16,
  },
});
