import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {getPropertyAddressFromProject} from 'lib/transformAddress';

import {sendEvent} from '@renofi/analytics';
import {Faqs} from '@renofi/components';
import {CottageFindPros, CottageHowItWorks} from '@renofi/modules';
import {Text, Flex, Container} from '@renofi/components-internal';
import {useToggledBorrowerProject, useGetUser} from '@renofi/graphql';

import faqs from './faqs';

const FindAPro = () => {
  const {user} = useGetUser();
  const {project} = useToggledBorrowerProject();

  const projectAddress = getPropertyAddressFromProject(project);
  const {city, streetAddressTwo, state, zipCode} = projectAddress || {};

  const stateZip = [state, zipCode].filter(Boolean).join(' ');

  const formattedLocation = [streetAddressTwo, city, stateZip]
    .filter(Boolean)
    .join(', ');

  useEffect(() => sendEvent('Secure/Find-A-Contractor-Page'), []);

  function handleClick() {
    sendEvent('Secure/Find-A-Contractor-Clicked');
    const urlParams = new URLSearchParams({
      firstname: user?.firstName || '',
      lastname: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phoneNumber || '',
      address: formattedLocation,
    });
    window.open(
      `https://cotta.ge/book-a-call?utm_source=renofi&utm_medium=referral&utm_campaign=renofisecure&utm_content=&${urlParams.toString()}`,
    );
  }

  return (
    <Container narrow mt={16} data-testid="renovation">
      <Flex justifyContent="center" flexDirection="column" width="100%">
        <CottageFindPros handleClick={handleClick} />
        <CottageHowItWorks handleClick={handleClick} />
        <Text fontSize="32px" paddingTop="40px" id="faq_section_title">
          FAQs
        </Text>
        <Faqs faqs={faqs} />
      </Flex>
    </Container>
  );
};

FindAPro.propTypes = {
  consecutiveTaskIds: PropTypes.arrayOf(PropTypes.string),

  user: PropTypes.object,
  storage: PropTypes.object,
};

export default React.memo(FindAPro);
