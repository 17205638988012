import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme';

import Flex from '../Flex';
import Box from '../Box';

export const Container = styled(Flex)({
  alignItems: 'center',
  svg: {
    cursor: 'pointer',
  },
});

export const StyledTitle = styled.span(
  {
    fontWeight: 700,
    color: '#212F49',
    '@media (max-width: 700px)': {
      fontSize: '27px',
    },
    [maxSmall]: {
      fontSize: '25px',
    },
  },
  ({fontSize}) => ({
    fontSize: fontSize || 40,
  }),
);

export const IconContainer = styled(Box)({
  marginRight: 16,
  marginBottom: 0,

  '&:hover': {
    svg: {
      color: '#df4748',
    },
  },
});
