import styled from '@emotion/styled';

import {Box, Button, HeadCell, Link} from '@renofi/components-internal';
import {
  ELLIPSIS_OVERFLOW,
  basic03,
  basic10,
  maxSmall,
  minSmall,
} from '@renofi/theme';

export const DesktopButton = styled(Button)({
  [maxSmall]: {
    display: 'none',
  },
});

export const MobileButton = styled(Button)({
  marginBottom: 24,
  [minSmall]: {
    display: 'none',
  },
});

export const Wrapper = styled(Box)({
  width: '100%',
  padding: '24px 16px',

  [maxSmall]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export const Action = styled(Link)({
  display: 'inline-block',
  marginLeft: 32,
  marginRight: 32,
  textAlign: 'center',

  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:last-of-type': {
    marginRight: 0,
  },
});

export const SwipeRow = styled.tr({
  [minSmall]: {
    display: 'none',
  },
});

export const TableWrapper = styled.div({
  [maxSmall]: {
    overflowX: 'scroll',
  },
});

export const Table = styled.table({
  width: '100%',
  borderSpacing: 0,
  border: `1px solid ${basic10}`,
  borderRadius: 8,
});

export const Td = styled.td({
  height: 60,
  padding: 8,
  borderTop: `1px solid ${basic10}`,

  '&:first-of-type': {
    paddingLeft: 16,
  },

  '&:last-of-type': {
    textAlign: 'right',
    paddingRight: 16,
  },
  [maxSmall]: {
    whiteSpace: 'nowrap',
  },
});

export const Th = styled(HeadCell)({
  height: 60,
  padding: 8,
  fontSize: 14,
  fontWeight: 'normal',
  textAlign: 'left',
  background: basic03,

  '&:first-of-type': {
    borderTopLeftRadius: 8,
    paddingLeft: 16,
  },

  '&:last-of-type': {
    borderTopRightRadius: 8,
    textAlign: 'right',
    paddingRight: 16,
  },
});

export const Tr = styled.tr(({isDeleted}) => ({
  opacity: isDeleted ? 0.3 : 1,
}));

export const Email = styled.div({
  ...ELLIPSIS_OVERFLOW,
  maxWidth: 100,
});
