import React from 'react';

import {ManageDocuments} from 'modules/tasks-wizard';

import {Flex, Text} from '@renofi/components-internal';
import {useSubmitContractorProjectDocument} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';

export default function Documents({projectId, contractor, task}) {
  const {submitContractorProjectDocument} =
    useSubmitContractorProjectDocument();

  function onUploadComplete(files) {
    sendEvent('Secure/Contractor-Documents-Uploaded', {
      projectId: projectId,
      taskId: task.id,
      contractorId: contractor.id,
      count: files.length,
    });
  }

  return (
    <Flex
      flexDirection="column"
      padding={['28px 16px', '20px 40px']}
      css={{
        borderTop: `1px solid #ccc`,
      }}>
      <Text pt={4} pb={4}>
        Please upload the most recent copy of any contracts, estimates, and
        plans provided by the contractor.
      </Text>
      <Flex mb={24} alignItems="center" css={{gap: 24}}>
        <ManageDocuments
          onUploadComplete={onUploadComplete}
          customMutation={submitContractorProjectDocument}
          customResponsePath="submitContractorProjectDocument"
          customMutationParams={(file) => {
            const {checksum, id: documentId, objectName} = file;

            return {
              document: {
                checksum,
                documentId,
                objectName,
              },
              projectId: projectId,
              contractorInformationId: contractor.id,
            };
          }}
          minimize
          taskId={task.id}
        />
      </Flex>
    </Flex>
  );
}
